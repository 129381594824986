import { CACNatureOfBusinessData, QueryResponseV2, BotNameReservationType, CACDownloadInterface, CompleteApplicationInterface, CustomerExplanationInterface, DeadLetterApplication, DeleteDocument, DocumentInterface, EditCompletedInterface, NewQueryInterface, OfflineApplication, PaymentDetailsInterface, QueryInterface, ToggleFlagInterface, DeadLetterToQueuePayload, DownloadedDocumentInterface, OfflineApplicationDetails, QueryResponseInterface } from '../../utils/typings/Applications';
import operationAxios, { operationsBaseUrl, InfraAxiosInstance } from '../../utils/AxiosConfig';
import { ApplicationProps, AssignService, CardProps, DefinitionDataProps, DetailsInterface, FormDefinitionProps, HideDocument, NameRegistrationBotProps, ServiceTimeline, ServiceTimelineDates, IDInterface, DeclineIncorporationProps, MarkAsResolvedPayload, SendToQueuePayload, SendQueriedToQueuePayload, BulkAcceptSuggestedNamesPayload, MuteQueryPayload, ReescalateQueryPayload, CreateFilingQueryPayload } from '../../utils/typings/Api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { loggerClient } from '../../utils/LoggerClient';
import { captureMessage } from '@sentry/react'
import { convertKeysToLowerCase } from '../../utils/helperFunctions/normalizeData';


//------------------APPLICATION PAGE REQUESTS-------------//

export const fetchApplications = async ({ country, searchText, fromDate, toDate, status, type, companyName, activeComponentTab, pageParam } : ApplicationProps): Promise<any> => {
    let path
    if(searchText !== ''){
        path = `search-orders/${pageParam}/${100}/${searchText}`
    }
    else if(searchText === ''){
        path = 'applications'
    }

    const url = new URL(`${operationsBaseUrl}OperationsAdmin/${path}`)

    if(searchText === ''){
        url.searchParams.set('pageSize', '100')
        if(country !== ''){
            url.searchParams.set('Country', country!)
        }
        if(pageParam){
            url.searchParams.set('Page', pageParam)
        }
        if(activeComponentTab === 'Norebase'){
            url.searchParams.set('Source', 'NOREBASE')
        }
        else if(activeComponentTab === 'Partner'){
            url.searchParams.set('Source', 'EXTERNAL')
        }
        else if(activeComponentTab === 'OFFLINE'){
            url.searchParams.set('Source', 'OFFLINE')
        }
        if(fromDate !== ''){
            url.searchParams.set('StartDate', fromDate!)
            url.searchParams.set('EndDate', toDate!)
        }
        if(companyName !== 'All' && activeComponentTab !== 'Norebase' && companyName !== '' && companyName !== undefined){
            url.searchParams.set('CompanyName', companyName?.toUpperCase())
        }
        if(status !== '' && status !== 'All'){
            url.searchParams.set('ApplicationStatus', status!)
        }
        else if(status === 'All'){
            url.searchParams.delete('ApplicationStatus')
        }
        if(type !== '' && type !== 'All'){
            url.searchParams.set('ApplicationType', type!)
        }
        else if(type === 'All'){
            url.searchParams.delete('ApplicationType')
        }
    }

    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`,{
        headers: {
            'x-continuation-token': pageParam, //the token is a variable which holds the token
            ApiTemplate: '/OperationsAdmin/applications',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchAtRiskApplications = async ({ searchText, pageParam } : ApplicationProps): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}AtRiskOrders/${pageParam}/100`)

    if(searchText !== ''){
        url.searchParams.set('search', searchText!)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/AtRiskOrders/${currentPage}/100',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const sendExplanationForAtRiskApplication = async(data:CustomerExplanationInterface):Promise<any> => {
    let response = await operationAxios.post(`AtRiskOrders/mail-customer`, data, {
        headers: {
            ApiTemplate: '/AtRiskOrders/mail-customer',
            StartTime: Date.now()
        }
    })
    return response.data.result
}

export const fetchAtRiskCommunication = async(id:string):Promise<any> => {
    let response = await operationAxios.get(`AtRiskOrders/detail/${id}`, {
        headers: {
            ApiTemplate: '/AtRiskOrders/detail/${id}',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const removeAtRiskApplication = async(applicationId:string):Promise<any> => {
    let response = await operationAxios.post(`AtRiskOrders/toggle-status/${applicationId}?status=${true}`, '', {
        headers: {
            ApiTemplate: '/AtRiskOrders/toggle-status/${applicationId}?status=${true}',
            StartTime: Date.now()
        }
    })
    return response.data.result
}

export const fetchDeadLetterApplications = async ({ collectionName, fromDate, toDate, pageParam, searchText, botName } : ApplicationProps): Promise<{
    data: DeadLetterApplication[]
    count: number;
    totalPages: number;
    totalItems: number;
    currentPage: string
}> => {
    const url = new URL(`${operationsBaseUrl}DeadLetterQueue/filter/${collectionName}`)
    url.searchParams.set('PageSize', '100')
    if(fromDate !== ''){
        url.searchParams.set('StartDate', fromDate!)
        url.searchParams.set('EndDate', toDate!)
    }
    if(pageParam){
        url.searchParams.set('Page', pageParam)
    }
    if(searchText !== '' && searchText !== undefined){
        url.searchParams.set('SearchTerm', searchText)
    }
    if(botName !== '' && botName !== undefined){
        url.searchParams.set('BotName', botName)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: `/DeadLetterQueue/filter/${collectionName}`,
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchDeadLetterSummary = async (): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}DeadLetterQueue/get-summary`)
    url.searchParams.set('PageSize', '100')
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/DeadLetterQueue/get-summary',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchDeadLetterApplication = async(applicationId : string ): Promise<DeadLetterApplication[]> => {
    return operationAxios.get(`DeadLetterQueue/get-deadletter-applications?SearchTerm=${applicationId}`, {
        headers: {
            ApiTemplate: '/DeadLetterQueue/get-deadletter-applications?SearchTerm=${applicationId}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result.data
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
}

export const fetchQueriedApplications = async ({ fromDate, toDate, activeSubTab, activeParentTab, pageParam, source, resolvedBy, queryType, companyName, showEscalated } : ApplicationProps): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}Query/queries`)

    setQueryParams(url, { activeParentTab, activeSubTab, queryType })

    setFilterParams(url, { fromDate, toDate, source, companyName, pageParam, resolvedBy, showEscalated })

    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/Query/queries',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

const setQueryParams = (url: URL, { activeParentTab, activeSubTab, queryType }: Partial<ApplicationProps>) => {
    if(activeParentTab === 'Closed Queries') {
        url.searchParams.set('Status', 'Completed')
        url.searchParams.set('IsResolved', 'true')
    }
    else if(activeParentTab === 'Escalated Queries') {
        if(queryType){
            url.searchParams.set('QueryType', queryType)
        }
        url.searchParams.set('Status', 'Approved')
        url.searchParams.set('EscalatedToPartner', 'true')
    }
    else if(activeParentTab === 'Open Queries') {
        setSubTabParams(url, activeSubTab, queryType)
    }
}

function setSubTabParams(url: URL, activeSubTab: string | undefined, queryType: string | undefined) {
    if(activeSubTab === 'Name Queries' || activeSubTab === 'Description Queries') {
        url.searchParams.set('Status', 'Approved')
        url.searchParams.set('IsResolved', 'false')
        url.searchParams.set('QueryType', activeSubTab === 'Name Queries' ? 'ChangeOfName' : 'ChangeOfDescription')
    }
    else if(activeSubTab === 'Responded Queries') {
        url.searchParams.set('Status', 'Completed')
        url.searchParams.set('IsResolved', 'false')
        if(queryType){
            url.searchParams.set('QueryType', queryType)
        }
    }
}

function setFilterParams(url: URL, { fromDate, toDate, source, companyName, pageParam, resolvedBy, showEscalated }: Partial<ApplicationProps>) {
    url.searchParams.set('PageSize', '100')
    if(pageParam){
        url.searchParams.set('PageNumber', pageParam)
    }
    if(resolvedBy){
        url.searchParams.set('ResolvedBy', resolvedBy)
    }
    if(showEscalated?.toString() === 'true' || showEscalated?.toString() === 'false'){
        url.searchParams.set('EscalatedToPartner', showEscalated.toString())
    }
    if(fromDate !== ''){
        url.searchParams.set('FromDate', fromDate!)
        url.searchParams.set('ToDate', toDate!)
    }
    if(source !== ''){
        url.searchParams.set('CreatedBy', source!)
    }
    if(companyName !== 'All' && companyName !== '' && companyName !== undefined){
        url.searchParams.set('CompanyName', companyName?.toUpperCase())
    }
}

export const fetchProposedClassifications = async ({ pageParam, classifiedStatus, applicationId, includeOthersQueries } : ApplicationProps): Promise<any> => {
    let url = new URL(`${operationsBaseUrl}Query/proposed-queries`)
    url.searchParams.set('PageSize', '500')
    if(pageParam){
        url.searchParams.set('PageNumber', pageParam)
    }
    if(classifiedStatus === 'Pending'){
        url.searchParams.set('IsClosed', 'false')
    }
    else if(classifiedStatus === 'Classified'){
        url.searchParams.set('IsApproved', 'true')
    }
    else if(classifiedStatus === 'Closed'){
        url.searchParams.set('IsClosed', 'true')
    }
    if(applicationId){
        url.searchParams.set('ApplicationId', applicationId)
    }
    if(includeOthersQueries?.toString() === 'true'){
        url.searchParams.set('IncludeOthersQueries', 'true')
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/Query/proposed-queries',
            StartTime: Date.now()
        }
    })
    return response.data.result
};



export const fetchOpexApplications = async ({ currentPage, searchText, fromDate, toDate, type, activeComponentTab, country, role, customerEmail, reviewerEmail} : ApplicationProps): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}OrderManager/applications`)
    url.searchParams.set('PageSize', '100')
    url.searchParams.set('Page', currentPage!.toString())

    if(activeComponentTab !== '' && activeComponentTab !== undefined){
        url.searchParams.set('ApplicationStatus', activeComponentTab)
    }
    if(searchText !== ''){
        url.searchParams.set('SearchTerm', searchText!)
    }
    if(fromDate !== ''){
        url.searchParams.set('StartDate', fromDate!)
        url.searchParams.set('EndDate', toDate!)
    }
    if(type !== '' && type !== 'All'){
        url.searchParams.set('ApplicationType', type!)
    }
    else if(type === 'All'){
        url.searchParams.delete('ApplicationType')
    }
    if(country !== ''){
        url.searchParams.set('Country', country!)
    }
    if(role !== ''){
        url.searchParams.set('Role', role!)
    }
    if(customerEmail !== ''){
        url.searchParams.set('CustomerEmail', customerEmail!)
    }
    if(reviewerEmail !== ''){
        url.searchParams.set('PartnerEmail', reviewerEmail!)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/OrderManager/applications',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchOpexMetrics = async ({ partnerEmail, type, fromDate, toDate, source, status, sla, country } : ApplicationProps): Promise<any> => {
    const url = new URL(`${operationsBaseUrl}OrderManager/metrics`)
    url.searchParams.set('PageSize', '100')

    //const requiredFilterAvailable = source !== '' || partnerEmail !== ''
    if(partnerEmail !== ''){
        url.searchParams.set('PartnerEmail', partnerEmail!)
    }
    if(source !== ''){
        url.searchParams.set('Source', source!)
    }
    if(sla !== ''){
        url.searchParams.set('SlaType', sla!)
    }
    if(type !== ''){
        url.searchParams.set('ApplicationType', type!)
    }
    if(fromDate !== ''){
        url.searchParams.set('StartDate', fromDate!)
        url.searchParams.set('EndDate', toDate!)
    }
    if(status !== ''){
        url.searchParams.set('ApplicationStatus', status!)
    }
    if(country !== ''){
        url.searchParams.set('Country', country!)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/OrderManager/metrics',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchOfflineApplications = async ({ pageParam, tin, registeredName, fromDate, toDate, applicationId } : ApplicationProps): Promise<any> => {
    let url = new URL(`${operationsBaseUrl}TinProcess`)
    url.searchParams.set('PageSize', '500')
    if(pageParam){
        url.searchParams.set('Page', pageParam)
    }
    if(tin !== ''){
        url.searchParams.set('Tin', tin!)
    }
    if(registeredName !== ''){
        url.searchParams.set('RegisteredName', registeredName!)
    }
    if(fromDate !== ''){
        url.searchParams.set('StartDate', fromDate!)
        url.searchParams.set('EndDate', toDate!)
    }
    if(applicationId){
        url.searchParams.set('ApplicationId', applicationId)
    }
    const parseUrl = url.toString()
    const urlIndex = parseUrl.indexOf(`/api/`) + 5
    const urlResult = parseUrl.substring(urlIndex)

    let response = await operationAxios.get(`${urlResult}`, {
        headers: {
            ApiTemplate: '/TinProcess',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchInfraDashboardCardData = async ({startDate='', endDate=''}: CardProps): Promise<any> => {
    let response = await operationAxios.get(`application/dashboard?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            ApiTemplate: '/application/dashboard?startDate=${startDate}&endDate=${endDate}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchDashboardCardData = async ({startDate, endDate}: CardProps): Promise<any> => {
    let response
    if(startDate === ''){
        response = await operationAxios.get(`Dashboard/operations?to=${endDate}`, {
            headers: {
                ApiTemplate: '/Dashboard/operations?from=${startDate}&to=${endDate}',
                StartTime: Date.now()
            }
        })
    }
    else{
        response = await operationAxios.get(`Dashboard/operations?from=${startDate}&to=${endDate}`, {
            headers: {
                ApiTemplate: '/Dashboard/operations?from=${startDate}&to=${endDate}',
                StartTime: Date.now()
            }
        })
    }
    return response.data
};





//------------------APPLICATION DETAILS PAGE REQUESTS-------------//


export const fetchApplicationDetails = async ({ applicationId, customerEmail }:DetailsInterface): Promise<any> => {
    const email = customerEmail?.replace('+', '')
    let response = await operationAxios.get(`OperationsAdmin/application/${applicationId}?email=${email}`, {
        headers: {
            ApiTemplate: '/OperationsAdmin/application/${applicationId}?email=${email}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchApplicationPreviewDetails = async ({ applicationId, companyId }:DetailsInterface): Promise<any> => {
    let response = await operationAxios.get(`application/${applicationId}?companyId=${companyId}`, {
        headers: {
            ApiTemplate: '/application/${applicationId}?companyId=${companyId}',
            StartTime: Date.now()
        }
    });
    if(response.data.result.applicationType === 'BusinessNameRegistration'){
        let data = response.data.result
        const normalizedData = convertKeysToLowerCase(response.data.result.applicationData)
        data.applicationData = normalizedData
        return data
    }
    return response.data.result
};

export const updateApplicationStatus = async (data:any): Promise<any> => {
    const email = data.email?.replace('+', '')
    let response = await operationAxios.put(`OperationsAdmin/change-application-status/${data.applicationId}/${email}/${data.status}`, '', {
        headers: {
            ApiTemplate: '/OperationsAdmin/change-application-status/${data.applicationId}/${data.email}/${data.status}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const createOfflineApplication = async (data:OfflineApplicationDetails): Promise<any> => {
    let response = await operationAxios.post(`TinProcess`, data, {
        headers: {
            ApiTemplate: '/TinProcess',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const updateUserApplication = async (applicationId:string, data:any, companyId:string): Promise<any> => {
    // const normalizedData = convertKeysToLowerCase(data)
    return InfraAxiosInstance.put(`application/${applicationId}/admin-update?companyId=${companyId}&submitNow=${true}`, { applicationData: data}, {
        headers: {
            ApiTemplate: '/application/${applicationId}/admin-update?companyId=${companyId}&submitNow=${true}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        let localLogs = JSON.parse(localStorage.getItem('editDataForLogs')!)
        const userData = JSON.parse(localStorage.getItem('userData')!)
        const userGroups = JSON.parse(localStorage.getItem('userGroups')!)
        const correlationId = localStorage.getItem('correlationId')!
        loggerClient.trackEvent('ApplicationDataUpdate', { userGroups, email: userData?.email ?? 'N/A', userId: userData?.userId ?? 'N/A', localLogs }, correlationId, 'POST')
        captureMessage('ApplicationDataUpdate', {
            level: "info",
            extra: {
                logs: localLogs,
                userGroups,
                email: userData?.email ?? 'N/A',
                userId: userData?.userId ?? 'N/A'
            },
        });
        return response.data.result
    }).catch((error:{ response: { data: { errors: any} }}) => {
        return Promise.reject(new Error(JSON.stringify(error.response.data.errors)))
    })
};

export const fetchProgressTracking = async (id:string): Promise<any> => {
    return InfraAxiosInstance.get(`ProcessProgressTracking/get-application-by-id/${id}?applicationId=${id}`, {
        headers: {
            ApiTemplate: '/ProcessProgressTracking/get-application-by-id/${id}?applicationId=${id}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const fetchNatureOfBusiness = async (applicationType:string): Promise<CACNatureOfBusinessData[]> => {
    let response = await InfraAxiosInstance.get(`NatureOfBusiness?applicationType=${applicationType}`, {
        headers: {
            ApiTemplate: '/NatureOfBusiness?applicationType=${applicationType}',
            StartTime: Date.now()
        }
    });
    return response.data
};

export const fetchExtraSuggestedNames = async (applicationId:string): Promise<any> => {
    let response = await InfraAxiosInstance.post(`NameSearch/businessname-all-possible-suggestions`,{ applicationId: applicationId }, {
        headers: {
            ApiTemplate: '/NameSearch/businessname-all-possible-suggestions',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const hideUserApplicationDocument = async ({ applicationId, url }:HideDocument) => {
    let response = await InfraAxiosInstance.post(`application/${applicationId}/document/toggle-status?isActive=${false}`, { url }, {
        headers: {
            ApiTemplate: '/application/${applicationId}/document/toggle-status?isActive=${false}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const toggleFlagOnApplication = async (data:ToggleFlagInterface) => {
    let response = await operationAxios.put(`OperationsAdmin/toggle-flag`, data, {
        headers: {
            ApiTemplate: '/OperationsAdmin/toggle-flag',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchFlagHistory = async (id:string) => {
    let response = await operationAxios.get(`OperationsAdmin/application-flag-comments/${id}`, {
        headers: {
            ApiTemplate: '/OperationsAdmin/application-flag-comments/${id}',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const fetchApplicationQueries = async ({ applicationId, customerEmail }:DetailsInterface): Promise<QueryInterface[]> => {
    let response = await operationAxios.get(`Query/application-queries/${applicationId}?email=${customerEmail}`, {
        headers: {
            ApiTemplate: '/Query/application-queries/${applicationId}?email=${customerEmail}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchProposedClassification = async ({ applicationId } :{ applicationId:string }): Promise<any> => {
    let response = await operationAxios.get(`Query/proposed-queries?applicationId=${applicationId}&isClosed=false`, {
        headers: {
            ApiTemplate: '/Query/proposed-queries?applicationId=${applicationId}&isClosed={false}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const sendApplicationQuery = async ({ data, customerEmail}:{ data: NewQueryInterface, customerEmail: string}) => {
    let response = await operationAxios.put(`Query/create-query/${customerEmail}`, data, {
        headers: {
            ApiTemplate: '/Query/create-query/${customerEmail}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const muteQuery = async (data:MuteQueryPayload) => {
    let response = await operationAxios.put(`Query/mute-query`, data, {
        headers: {
            ApiTemplate: '/Query/mute-query',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const respondToQuery = async ({ applicationId, customerEmail, data}:{ applicationId: string, data: QueryResponseInterface, customerEmail: string}) => {
    return operationAxios.put(`Query/accept-suggested-names/${applicationId}/${customerEmail}`, data, {
        headers: {
            ApiTemplate: '/Query/accept-suggested-names/${applicationId}/${customerEmail}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const bulkAcceptSuggestion = async (data:BulkAcceptSuggestedNamesPayload[]) => {
    return operationAxios.put(`Query/bulk-accept-suggested-names`, data, {
        headers: {
            ApiTemplate: '/Query/bulk-accept-suggested-names',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const createFilingQuery = async (data:CreateFilingQueryPayload) => {
    let response = await operationAxios.post(`Query/create-proposed-query`, data, {
        headers: {
            ApiTemplate: '/Query/create-proposed-query',
            StartTime: Date.now()
        }
    });
    return response.data.result
}

export const createClassifiedQuery = async ({ proposedQueryId, email, data }:{ proposedQueryId: string, email: string, data: NewQueryInterface}) => {
    let response = await operationAxios.post(`Query/create-approved-classified-query/${proposedQueryId}/${email}`, data, {
        headers: {
            ApiTemplate: '/Query/create-approved-classified-query/${proposedQueryId}/${email}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const closeClassifiedQuery = async ({ proposedQueryId, applicationId }:{ proposedQueryId: string, applicationId: string}) => {
    let response = await operationAxios.put(`Query/close-proposed-query/${proposedQueryId}/${applicationId}`, '',{
        headers: {
            ApiTemplate: '/Query/close-proposed-query/${proposedQueryId}/${applicationId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const escalateQueryToPartner = async (data:{ queryId: string, applicationId: string}) => {
    let response = await operationAxios.put(`Query/escalate-query-to-partner?applicationId=${data.applicationId}&queryId=${data.queryId}`, '',
    {
        headers: {
            ApiTemplate: '/Query/escalate-query-to-partner?applicationId=${applicationId}&queryId=${queryId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const reescalateQuery = async (data:ReescalateQueryPayload): Promise<any> => {
    let response = await operationAxios.put(`Query/reject-query-response`, data , {
        headers: {
            ApiTemplate: '/Query/reject-query-response',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const getQueryMetrics = async (fromDate:string, toDate:string) => {
    let response = await operationAxios.get(`Query/metrics?FromDate=${fromDate}&ToDate=${toDate}`,
    {
        headers: {
            ApiTemplate: '/Query/metrics',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const getReviewerMetrics = async (reviewerFromDate:string, reviewerToDate:string) => {
    let response = await operationAxios.get(`Query/reviewer-metrics?FromDate=${reviewerFromDate}&ToDate=${reviewerToDate}`,
    {
        headers: {
            ApiTemplate: '/Query/reviewer-metrics',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const resendApplicationQuery = async ({ data }:{ data: QueryInterface }) => {
    let response = await operationAxios.put(`Query/send-query-to-customer?applicationId=${data.applicationId}&email=${data.email}&queryId=${data.queryId}`, '', {
        headers: {
            ApiTemplate: '/Query/send-query-to-customer?applicationId=${data.applicationId}&email=${data.email}&queryId=${data.queryId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const sendQueriedApplicationToQueue = async (data: SendQueriedToQueuePayload) => {
    return operationAxios.put(`Query/application-to-queue-from-query`, data, {
        headers: {
            ApiTemplate: '/Query/application-to-queue-from-query',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(error.response.data.message)
    })
};

export const sendDeadLetterApplicationToQueue = async (data: DeadLetterToQueuePayload) => {
    return operationAxios.put(`Query/application-to-queue-from-deadletter`, data, {
        headers: {
            ApiTemplate: '/Query/application-to-queue-from-deadletter',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(error.response.data.message)
    })
};

export const deleteFromQueue = async (data:string[]) => {
    return operationAxios.delete(`DeadLetterQueue/delete-multiple`, {
        data: data,
        headers: {
            ApiTemplate: '/DeadLetterQueue/delete-multiple',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const markQueryAsResolved = async ( data: MarkAsResolvedPayload) => {
    let response = await operationAxios.put(`Query/mark-as-resolved`, data, {
        headers: {
            ApiTemplate: '/Query/mark-as-resolved',
            StartTime: Date.now()
        }
    });
    return response.data.result
};


export const sendApplicationToQueue = async (data: SendToQueuePayload) => {
    return operationAxios.put(`OperationOrderProcess/send-application-to-queue`, data, {
        headers: {
            ApiTemplate: '/OperationOrderProcess/send-application-to-queue',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(error.response.data.message)
    })
};

export const assignApplication = async ({ applicationId, userEmail, type, customerEmail}:DetailsInterface) => {

    let response = await operationAxios.put(`OperationsAdmin/assign-application/${applicationId}/${userEmail}/${customerEmail}?isSupervisor=${false}`, '', {
        headers: {
            ApiTemplate: '/OperationsAdmin/assign-application/${applicationId}/${userEmail}/${customerEmail}?isSupervisor=${false}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchCompletionApplicationDocuments = async ({ applicationId, customerEmail}:DetailsInterface) => {
    const email = customerEmail?.replace('+', '')
    let response = await operationAxios.get(`Document/application-documents/${applicationId}/${email}`, {
        headers: {
            ApiTemplate: '/Document/application-documents/${applicationId}/${customerEmail}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const updatePaymentDetails = async (data:PaymentDetailsInterface) => {
    let response = await operationAxios.put(`OperationsAdmin/update-payment-detail`, data, {
        headers: {
            ApiTemplate: '/OperationsAdmin/update-payment-detail',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const downloadApplicationDocument = async (url:{ url: string }) => {
    let response = await operationAxios.post(`Document/download-document`, url, {
        headers: {
            ApiTemplate: '/Document/download-document',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const fetchApplicationDocuments = async ({ applicationId }:DetailsInterface) => {
    let response = await operationAxios.get(`application/documents/${applicationId}`, {
        headers: {
            ApiTemplate: '/application/documents/${applicationId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const downloadMultipleApplicationDocuments = async (documents:DocumentInterface[] | QueryResponseV2[]):Promise<DownloadedDocumentInterface[]> => {

    const documentUrls = documents?.map((doc:any) => doc.attachmentUrl ?? doc.documentUrl ?? doc.customerResponse)

    const requests = documentUrls?.map((url) => operationAxios.post(`Document/download-document`, { url: url}, {
        headers: {
            ApiTemplate: '/Document/download-document(MultipleDocumentsDownload)',
            StartTime: Date.now()
        }
    }));


    let result:DownloadedDocumentInterface[] = []
    await Promise.allSettled(requests).then(responses => {
        const fulfilledResponses = responses
            .filter(response => response.status === 'fulfilled')
            .map(res => res.status === 'fulfilled' && res?.value.data.result)
        result = fulfilledResponses
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    });
    return result
};

export const fetchCustomerApplications = async (email:string) => {
    let response = await operationAxios.get(`OperationsAdmin/applications?CustomerEmail=${email}`, {
        headers: {
            ApiTemplate: '/OperationsAdmin/applications?CustomerEmail=${email}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const fetchCustomerQueries = async (id:string, email:string) => {
    let response = await operationAxios.get(`Query/customer-queries/${id}/${email}`, {
        headers: {
            ApiTemplate: '/Query/customer-queries/${id}/${email}',
            StartTime: Date.now()
        }
    });
    return response.data.result.data
};


export const uploadDocuments = async (data:any) => {
    const MAX_FILE_SIZE = 10485760

    const fileSizeKiloBytes = data.get('Attachment')?.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        toast.warning("Document is too large", {
            toastId: "document-too-large"
        });
        throw new Error()
    }

    const uploadcConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            ApiTemplate: '/Document/upload-application-document',
            StartTime: Date.now()
        },
    }


    return operationAxios.put(
            'Document/upload-application-document',
            data,
            uploadcConfig
    ).then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};

export const downloadDocumentsFromCAC = async(data:CACDownloadInterface):Promise<any> => {
    let response = await axios.post(`https://fa-bot-weustart.azurewebsites.net/api/ManualDocumentDownload`, data, {
        headers: {
            ApiTemplate: 'https://fa-bot-weustart.azurewebsites.net/api/ManualDocumentDownload',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const uploadNewDocuments = async (data:any) => {
    const MAX_FILE_SIZE = 10485760

    const fileSizeKiloBytes = data.get('Attachment')?.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        toast.warning("Document is too large", {
            toastId: "document-too-large"
        });
        throw new Error()
    }

    const uploadcConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            ApiTemplate: '/application/upload',
            StartTime: Date.now()
        },
    }

    return InfraAxiosInstance.post(`application/upload`, data, uploadcConfig)
        .then(response => {
            let localLogs = JSON.parse(localStorage.getItem('editDocumentsForLogs')!)
            const userData = JSON.parse(localStorage.getItem('userData')!)
            const userGroups = JSON.parse(localStorage.getItem('userGroups')!)
            const correlationId = localStorage.getItem('correlationId')!
            loggerClient.trackEvent('ApplicationDocumentUpdate', { userGroups, email: userData?.email ?? 'N/A', userId: userData?.userId ?? 'N/A', localLogs }, correlationId, 'PUT')
            return response.data
        }).catch((error:{ response: { data: { message: string }}}) => {
            return Promise.reject(new Error(error.response.data.message))
        })
};

export const deleteDocument = async (data:DeleteDocument) => {
    let response = await operationAxios.delete(`Document/delete-document/${data.documentId}/${data.applicationId}/${data.customerEmail}`, {
        headers: {
            ApiTemplate: '/Document/delete-document/${data.documentId}/${data.applicationId}/${data.customerEmail}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const uploadGenericDocuments = async (data:any) => {
    const MAX_FILE_SIZE = 10485760

    const fileSizeKiloBytes = data.get('Attachment')?.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        toast.warning("Document is too large", {
            toastId: "document-too-large"
        });
        throw new Error()
    }
    let response = await operationAxios.put(
        'Document/upload-document',
        data,
        {
            headers: {
                ApiTemplate: '/Document/upload-document',
                StartTime: Date.now()
            }
        }
    );
    return response.data.result
};

export const completeApplication = async (data:CompleteApplicationInterface) => {
    let response = await operationAxios.put(`OperationsAdmin/mark-as-complete`, data, {
        headers: {
            ApiTemplate: '/OperationsAdmin/mark-as-complete',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const declineCompleteApplication = async (data:DeclineIncorporationProps) => {
    let response = await operationAxios.put(`OrderManager/validate`, data, {
        headers: {
            ApiTemplate: '/OrderManager/validate',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const editCompleteApplication = async (data:EditCompletedInterface) => {
    let response = await operationAxios.put(`/Company/update-company`, data, {
        headers: {
            ApiTemplate: '/Company/update-company',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const markAsPaid = async (data: IDInterface): Promise<any> => {
    let response = await operationAxios.get(`application/mark-as-paid/${data.applicationId}/${data.companyId}`, {
        headers: {
            ApiTemplate: '/application/mark-as-paid/${data.applicationId}/${data.companyId}',
            StartTime: Date.now()
        }
    })
    return response.data
};


export const initializeApplication = async (data: any): Promise<any> => {
    let response = await operationAxios.put(`OperationsAdmin/initialize-timeline/${data.applicationId}/${data.customerEmail}`, '', {
        headers: {
            ApiTemplate: '/OperationsAdmin/initialize-timeline/${data.applicationId}/${data.customerEmail}',
            StartTime: Date.now()
        }
    })
    return response.data.result
};

export const generateTin = async (applicationId:string): Promise<any> => {
    return operationAxios.get(`OperationsAdmin/get-tin/${applicationId}`, {
        headers: {
            ApiTemplate: '/OperationsAdmin/get-tin/${applicationId}',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(new Error(error.response.data.message))
    })
};


export const addOfflineApplication = async (data: OfflineApplication): Promise<any> => {
    let response = await operationAxios.put(`OperationsAdmin/create-offline-transaction`, data, {
        headers: {
            ApiTemplate: '/OperationsAdmin/create-offline-transaction',
            StartTime: Date.now()
        }
    })
    return response.data.result
};


export const fetchServiceTimelines = async ({ applicationId, customerEmail } : ServiceTimeline): Promise<any> => {
    let response = await operationAxios.get(`ServiceTimelineManager/application-timelines/${applicationId}/${customerEmail}`, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/application-timelines/${applicationId}/${customerEmail}',
            StartTime: Date.now()
        }
    })
    return response.data.result.data
};

export const fetchSpecificService = async ({ applicationId, customerEmail, timelineId}:ServiceTimeline) => {
    let response = await operationAxios.get(`ServiceTimelineManager/view-timeline/${applicationId}/${customerEmail}/${timelineId}`, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/view-timeline/${applicationId}/${customerEmail}/${timelineId}',
            StartTime: Date.now()
        }
    });
    return response.data.result
};

export const assignService = async (data:AssignService): Promise<any> => {
    let response = await operationAxios.put(`ServiceTimelineManager/assign-partner-to-application-event-v2`, data, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/assign-partner-to-application-event-v2',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const completeService = async (data:AssignService): Promise<any> => {
    return operationAxios.put(`ServiceTimelineManager/update-timeline-result`, data, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/update-timeline-result',
            StartTime: Date.now()
        }
    })
    .then(response => {
        return response.data.result
    }).catch((error:{ response: { data: { message: string }}}) => {
        return Promise.reject(error.response.data.message)
    })
};

export const updateServiceDates = async (data:ServiceTimelineDates): Promise<any> => {
    let response = await operationAxios.put(`ServiceTimelineManager/timeline-date-manager`, data, {
        headers: {
            ApiTemplate: '/ServiceTimelineManager/timeline-date-manager',
            StartTime: Date.now()
        }
    })
    return response.data
};

export const getFormDefinitions = async(data:FormDefinitionProps, definition:string):Promise<any> => {
    //definition is get-ss4-definitions & get-8821-definitions
    let response = await operationAxios.get(`DelawarePostIncorporationForm/${definition}/${data.applicationId}/${data.customerEmail}`, {
        headers: {
            ApiTemplate: '/DelawarePostIncorporationForm/${definition}/${data.applicationId}/${data.customerEmail}',
            StartTime: Date.now()
        }
    })
    return response.data.result
}

export const downloadFormDefinitions = async(data:DefinitionDataProps):Promise<any> => {
    let response = await operationAxios.post(`DelawarePostIncorporationForm/download-filled-form`, data, {
        headers: {
            ApiTemplate: '/DelawarePostIncorporationForm/download-filled-form',
            StartTime: Date.now()
        }
    })
    return response.data.result
}

export const nameReservationBot = async(data:BotNameReservationType):Promise<any> => {
    let response = await axios.post(`https://fa-bot.azurewebsites.net/api/NameReservationBot`, data, {
        headers: {
            ApiTemplate: 'https://fa-bot.azurewebsites.net/api/NameReservationBot',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const businessNameRegistrationBot = async({applicationId, companyId, data}:NameRegistrationBotProps):Promise<any> => {
    let response = await axios.post(`https://fa-bot.azurewebsites.net/api/NigeriaBusinessRegistrationBot?applicationId=${applicationId}&companyId=${companyId}`, data, {
        headers: {
            ApiTemplate: 'https://fa-bot.azurewebsites.net/api/NigeriaBusinessRegistrationBot?applicationId=${applicationId}&companyId=${companyId}',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const delawareRegistrationBot = async(data: IDInterface):Promise<any> => {
    let response = await axios.post(`https://fa-delawarebot-uks-dev.azurewebsites.net/api/delaware_bot`, data, {
        headers: {
            ApiTemplate: 'https://fa-delawarebot-uks-dev.azurewebsites.net/api/delaware_bot',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const natureOfBusinessCategoryRequest = async(id: string):Promise<any> => {
    let response = await axios.get(`https://fa-bot.azurewebsites.net/api/CACCategories?id=${id}`, {
        headers: {
            ApiTemplate: 'https://fa-bot.azurewebsites.net/api/CACCategories?id=${id}',
            StartTime: Date.now()
        }
    })
    return response.data
}

export const fetchBotTransaction = async({ applicationId }: { applicationId: string}):Promise<any> => {
    let response = await operationAxios.get(`Bot/bot-transactions/${applicationId}`, {
        headers: {
            ApiTemplate: '/Bot/bot-transactions/${applicationId}',
            StartTime: Date.now()
        }
    })
    return response.data.result
}