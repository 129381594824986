import { MdOutlineClose } from 'react-icons/md'
import { ModalInterfaceWrapper } from '../../../utils/typings/Shared'


function ModalWrapper({ children, onClose, sideModal=false, height, showOnTop, zIndex }: ModalInterfaceWrapper) {
    return (
        <div className={`fixed ${showOnTop ? 'z-40' : 'z-30'} ${zIndex} h-screen top-0 left-0 flex items-center ${sideModal ? 'justify-end' : 'justify-center'} w-full `}>
            <div onClick={onClose} className='h-screen w-full absolute z-20 bg-gray'></div>
            <div className={`${sideModal ? 'h-screen py-6 w-full lg:w-2/5 lg:max-w-md' : 'h-3/4 md:h-3/5 lg:max-h-fit p-6 rounded-md w-96'} ${height ? ' h-fit' : '' } xl:max-w-2xl bg-white  md:w-3/5 lg:max-w-2xl z-50 overflow-y-auto`}>
                <div onClick={onClose} className='absolute z-50 right-0 cursor-pointer px-6'>
                    <span><MdOutlineClose size={20}/></span>
                </div>
                { children }
            </div>
        </div>
    )
}

export default ModalWrapper