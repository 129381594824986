import { byteToUint8Array } from '../constant';
import { DownloadDocumentInterface } from '../typings/Applications';
import { toast } from 'react-toastify';

export const downloadFile = (data:DownloadDocumentInterface) => {
    const name = data.fileName;
    const arrayBuffer = byteToUint8Array(data?.file)
    const blob = new Blob([arrayBuffer], { type: data?.contentType })
    
    const aElement = document.createElement('a');
    aElement.setAttribute('download', name);
    const href = window.URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    window.URL.revokeObjectURL(href);

    toast.success("Download successful", {
        toastId: "download-successful"
    });
}
