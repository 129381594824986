export const createGuid = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const CustomNumberFormat=(style: any, currency: string, amount: number)=>{
    return new Intl.NumberFormat('en-US', {
      style: style,
      currency: currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
    }).format(amount)
  }

export const EditPricingAccess = ['esther@norebase.com', 'tola@norebase.com', 'ope@norebase.com', 'bright@norebase.com', 'adefola@norebase.com', 'dominic@norebase.com']
export const exportUserAccess = ['esther@norebase.com', 'salem@norebase.com', 'tope@norebase.com', 'dominic@norebase.com', 'bright@norebase.com']
export const shippedCountries = ['Nigeria', 'Rwanda', 'Ghana', 'Kenya', 'SouthAfrica', 'USADelaware', 'USAWyoming', 'Senegal', 'CIV', 'Uganda', 'BritishVirginIslands'];
export const EditStatusAccess = ['esther@norebase.com', 'victoria@norebase.com', 'ope@norebase.com', 'dominic@norebase.com', 'kamara@norebase.com', 'salem@norebase.com', 'charles@norebase.com']

export const contentTypes = [
  {
    type: 'image',
    extension: 'jpg'
  },
  {
    type: 'image',
    extension: 'jpeg'
  },
  {
    type: 'image',
    extension: 'png'
  },
  {
    type: 'pdf',
    extension: 'pdf'
  },
]

export const contentTypeStrings = ['pdf', 'jpg', 'jpeg', 'png']
interface DocumentsType {
  [key: string]: {
    BusinessNameRegistration?: string[],
    CompanyIncorporation: string[],
    Trademark: string[] | [],
  };
}

export const IncorporationDocumentName: DocumentsType = {
    'Nigeria': {
        'BusinessNameRegistration': [
            'Business Name Certificate',
            'Memorandum of Association',
            'Status Report',
            //'TIN Certificate'
        ],
        'CompanyIncorporation' : [
            'Certificate of Incorporation',
            'Memorandum of Association',
            'Status of Registration'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    },
    'Rwanda': {
        'CompanyIncorporation': [
            'Full Registration Information of Company',
            'Certificate of Company Registration.',
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'Kenya': {
        'CompanyIncorporation': [
            'Certificate of Incorporation',
            'Statement of Nominal Capital',
            'Company Registration Form',
            'Name Reservation Certificate',
            'Memorandum of Association',
            'Status Report',
            'Register of Beneficial Owner'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'SouthAfrica': {
        'CompanyIncorporation': [
            'Registration / disclosure certificate - COR14.3',
            'Notice of incorporation - COR14.1',
            'Notice of incorporation - Initial directors - COR14.1A',
            'Memorandum of incorporation - COR15.1A',
            'Securities Register',
            'Share Certificate 1',
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'South Africa': {
        'CompanyIncorporation': [
            'Registration / disclosure certificate - COR14.3',
            'Notice of incorporation - COR14.1',
            'Notice of incorporation - Initial directors - COR14.1A',
            'Memorandum of incorporation - COR15.1A',
            'Securities Register',
            'Share Certificate 1',
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'Delaware, USA': {
        'CompanyIncorporation': [
            'Certification of Formation'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'USADelaware': {
        'CompanyIncorporation': [
            'Certification of Formation'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'USAWyoming': {
        'CompanyIncorporation': [
            'Certification of Formation',
            'Initial Resolutions',
            'Free Basic Operating Agreement'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'BritishVirginIslands': {
        'CompanyIncorporation': [
            'Certificate of Incorporation',
            'Memorandum of Association',
            'Register of Directors',
            "Director’s Acceptance Letter",
            'Register of Members',
            'Share Certificate',
            'Company Seal (hard copy)'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'British Virgin Islands': {
        'CompanyIncorporation': [
            'Certificate of Incorporation',
            'Memorandum of Association',
            'Register of Directors',
            "Director’s Acceptance Letter",
            'Register of Members',
            'Share Certificate',
            'Company Seal (hard copy)'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'Senegal': {
        'CompanyIncorporation': [
            'Articles of association',
            'Proof of deposit of articles with the notary',
            'Declaration of authenticity of the deeds',
            "Original NINEA",
            'Declaration of registration with RCCM',
            'Declaration of existence at RCCM',
            'Acknowledgement of company formation',
            'Newspaper publication',
            'Statement of Existence',
            'Trade Register',
            'Legal Gazette',
            'Cheque with share capital'
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'CIV': {
        'CompanyIncorporation': [
            'Single Certificate of Incorporation',
            'Declaration of Subscription to Share Capital',
            'Lease Agreement',
            'Notice of Incorporation',
            'Articles of Association',
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'Uganda': {
        'CompanyIncorporation': [
            'Certificate of Incorporation',
            'Memorandum of Association',
            'Notification of Appointment of Director and Secretary',
            'Notice of Nomination of Alternate and Nominee Director (SMC LTD)',
        ],
        'Trademark': [
            'Proof of Filing'
        ]
    }, 
    'Tanzania': {
        'CompanyIncorporation': [],
        'Trademark': []
    }, 
    'Egypt': {
        'CompanyIncorporation': [],
        'Trademark': []
    }, 
    'Dubai': {
        'CompanyIncorporation': [],
        'Trademark': []
    }, 
    'Burkina Faso': {
        'CompanyIncorporation': [],
        'Trademark': []
    }, 
}

export const addSpaceToTitle = (value: string): string => {
    if(value === undefined || value === null || value === ''){
      return ''
    }
    return value?.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2')
}

export const checkTokenExpire = () => {
    const token =  localStorage.getItem('operations_access_token')!
    const jwtPayload  = JSON.parse(window.atob(token.split('.')[1]))
    const isExpired = Date.now() >= jwtPayload.exp * 1000;
    return isExpired
}

export function byteToUint8Array(byteArray: string) {
    const binaryString = window.atob(byteArray)
    var bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i))
}



