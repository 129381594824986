
export function convertKeysToLowerCase(obj: any): any {
    if (Array.isArray(obj)) {
        // If the input is an array, recursively process each element
        return obj.map((item) => convertKeysToLowerCase(item));
    } else if (typeof obj === 'object' && obj !== null) {
        // If the input is an object, convert keys to lowercase
        const convertedObject: { [key: string]: any } = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
                convertedObject[lowerCaseKey] = convertKeysToLowerCase(obj[key]);
            }
        }
        return convertedObject;
    } else {
        // For non-object values (e.g., strings, numbers), return as is
        return obj;
    }
}
