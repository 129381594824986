import { useMutation, useQuery } from "@tanstack/react-query"
import { downloadApplicationDocument, fetchApplicationDocuments } from "../api/Applications"
import { useParams } from "react-router-dom";
import { byteToUint8Array, contentTypeStrings } from "../../utils/constant";
import { toast } from "react-toastify";
import { downloadFile } from "../../utils/helperFunctions/downloadFile";



export const useApplicationDocumentsData = () => {

    let { id } = useParams();

    const { isError, isLoading, data } = useQuery({
        queryKey: ['application-documents', id],
        queryFn: () => fetchApplicationDocuments({ applicationId:id! }),
        staleTime: 0,
        cacheTime: 0,
        enabled: !!id
    })

    return { isError, isLoading, data }
}


type UseDownloadDocumentType = { 
    queryKey: string
    refetchTrigger?: any, 
    setPdf: (value: React.SetStateAction<string | undefined>) => void,
    setImg: (value: React.SetStateAction<string | undefined>) => void,
    setFile?: (value: React.SetStateAction<string | undefined>) => void,
    enabled: boolean  
    queryData: {
        url: string
    }
}
export const useDownloadDocuments = ({ queryKey, refetchTrigger, setImg, setPdf, setFile, enabled, queryData }: UseDownloadDocumentType ) => {

    const { isError, isFetching, data } = useQuery({
        queryKey: [queryKey, refetchTrigger],
        queryFn: () => downloadApplicationDocument(queryData),
        enabled: enabled,
        //staleTime: 0,
        onSuccess: (data) => {
            setFile && setFile(data?.fileName)
            const type = data?.contentType.split('/')[1]
            let fileType = ''
            const arrayBuffer = byteToUint8Array(data?.file!)
            if(type === 'octet-stream'){
                const typeFromFile = data.fileName.substring(data.fileName.length - 3)
                if(contentTypeStrings.includes(typeFromFile)){
                    fileType = typeFromFile
                }
                else{
                    fileType = 'jpeg'
                }
            }
            if(fileType === 'pdf' || type === 'pdf'){
                setPdf(data.file)
                setImg('')
            }
            else {
                const blob = new Blob([arrayBuffer], { type: type! })
                setImg(URL.createObjectURL(blob))
                setPdf('')
            }
        }
    })

    return { isError, isFetching, data }
}


export const useDownloadMutation = () => {

    const downloadMutation = useMutation({
        mutationFn: (data:{ url: string }) => downloadApplicationDocument(data),
        onError: () => {
            toast.error("Download failed", {
                toastId: "download-failed"
            });
        },
        onSuccess: (data) => {
            downloadFile(data)
    
            toast.success("Download successful", {
                toastId: "download-successful"
            });
        },
    })

    return downloadMutation
}